import "@fontsource/roboto";
import "@fontsource/roboto-condensed";

import MySwiper from "./parts/MySwiperV2";
import Games from "./parts/Games";
import Content from "./parts/Content";

function Inicio({ flag }) {
  return (
    <div className="_inicio">
      <MySwiper device={flag} />
      <div className="">
        <Games device={flag} />
        <Content />
      </div>
    </div>
  );
}

export default Inicio;
