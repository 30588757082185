import {
  Accordion,
  Button,
  Container,
  Dropdown,
  InputGroup,
  Nav,
  Navbar,
  Image,
} from "react-bootstrap";

import React, { useState } from "react";
import { slide as Menu } from "react-burger-menu";
import {
  Arrowrightw,
  Arrowdown,
  Aviator,
  CasinoMenuIcon,
  Deportes,
  Directo,
  Promociones,
  Slots,
  Omnichannel,
  CasinoEnVivo,
} from "../icons";
import { SPEEDSIZE_PREFIX } from "../App";

const LOGO = "https://www.codere.es/assets1/icons/logo-deportes.svg";

function Header({ device }) {
  const acceder =
    "https://m.apuestas.codere.es/deportesEs/#/HomePage?openlogin=true";

  const regis = "https://m.apuestas.codere.es/deportesEs/#/mso/RegistroNewPage";
  const prefix = "https://www.codere.es";

  const [hamburger, setHamburger] = useState(false);
  const [onShow, setOnShow] = useState("");

  const data = [
    {
      url: "https://m.apuestas.codere.es/deportesEs/#/HomePage",
      text: "Deportes",
      icon: Deportes,
    },
    {
      url: "https://m.apuestas.codere.es/deportesEs/#/DirectosPage",
      text: "En vivo",
      icon: Directo,
    },
    {
      url: "https://m.apuestas.codere.es/deportesEs/#/casino/SlotsPage?lobby=Nacional",
      text: "Slots",
      icon: Slots,
    },
    {
      url: "https://m.apuestas.codere.es/deportesEs/#/casino/CasinoPage?lobby=Playtech",
      text: "Casino",
      icon: CasinoMenuIcon,
    },
    {
      url: "https://m.apuestas.codere.es/deportesEs/#/casino/CasinoEnVivoPage?lobby=Casino%20En%20Vivo",
      text: "Casino En vivo",
      icon: CasinoEnVivo,
    },
    {
      url: "https://m.apuestas.codere.es/deportesEs/#/casino/CasinoPage?lobby=Aviator&singleGame=true",
      text: "Aviator",
      isAviator: true,
      icon: Aviator,
    },
    {
      url: "https://m.apuestas.codere.es/deportesEs/#/mso/PromotionsPage",
      text: "Promociones",
      icon: Promociones,
    },
    {
      url: "https://m.apuestas.codere.es/deportesEs/#/mso/NearestLocalPage",
      text: "Locales",
      icon: Omnichannel,
    },
  ];

  const seo_menu = [
    { name: "Casino", url: "casino" },
    { name: "Casino en Vivo", url: "casino/casino-en-vivo" },
    { name: "Ruleta", url: "casino/ruleta" },
    { name: "Slots", url: "casino/slots" },
    { name: "Blackjack", url: "casino/blackjack" },
    { name: "Jackpots", url: "casino/jackpots" },
    { name: "Eventos Deportivos", url: "eventos-deportivos" },
    { name: "Apuestas Recomendades", url: "cuotas-deportivas" },
    { name: "Codere Blog", url: "https://blog.codere.es/", target: "_blank" },
  ];

  const hamburgerHandler = () => {
    hamburger ? setOnShow("") : setOnShow("open");
    setHamburger(!hamburger);
  };

  return (
    <Navbar className="header_nav stroke" variant="dark">
      {device ? (
        <div id="father">
          <div id="outer-container">
            <Menu
              id="elastic"
              left
              customCrossIcon={false}
              // pageWrapId={"page-wrap"}
              outerContainerId={"outer-container"}
              burgerButtonClassName={onShow}
              isOpen={hamburger}
              onOpen={hamburgerHandler}
              onClose={hamburgerHandler}
            >
              {data.map((d, k) => (
                <a key={k} href={d.url} rel="nofollow" className={"bm-menu"}>
                  <div
                    className={"mobMenuSVGwrapper d-flex align-items-center"}
                  >
                    {React.createElement(d.icon, {
                      className: "mobileMenuIcon",
                    })}
                    {d.text}
                  </div>

                  <div className={"arrowright"}>
                    <Arrowrightw />
                  </div>
                </a>
              ))}

              <Accordion className="hamb-accordion">
                <Accordion.Item eventKey="0">
                  <Accordion.Header as={"span"}>
                    <span style={{ color: "#79c000" }}>¿Cómo jugar?</span>
                  </Accordion.Header>
                  <Accordion.Body>
                    <ul>
                      {seo_menu.map((s, k) => (
                        <li key={k}>
                          <a
                            onClick={hamburgerHandler}
                            href={`${!s.target ? `${prefix}/` : ""}${s.url}`}
                            target={s.target || null}
                          >
                            {s.name}
                            <Arrowrightw />
                          </a>
                        </li>
                      ))}
                    </ul>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </Menu>
            {/* <main id="page-wrap"></main> */}
            <div className="header-wrapper d-flex justify-content-evenly">
              <a
                href="https://m.apuestas.codere.es/deportesEs/#/HomePage"
                rel="nofollow"
              >
                <img
                  width={250}
                  height={30}
                  src={`${SPEEDSIZE_PREFIX}/${LOGO}`}
                  alt="Codere Casino Online en España"
                />
              </a>
              <div className="d-flex w-100 align-items-center">
                <Button
                  href={acceder}
                  rel="nofollow"
                  className="acceder-button header_btn me-2"
                >
                  Acceder
                </Button>
                <Button
                  href={regis}
                  rel="nofollow"
                  className="registrate-button header_btn"
                >
                  Regístrate
                </Button>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <Container>
          <InputGroup className="header-group-left">
            <a
              href="https://m.apuestas.codere.es/deportesEs/#/HomePage"
              rel="nofollow"
            >
              <Image
                style={{ maxWidth: 150 }}
                width={150}
                height={35}
                src={`${SPEEDSIZE_PREFIX}/${LOGO}`}
                alt="Codere Casino Online en España"
              />
            </a>
            <Nav className="me-auto">
              {data.map((d, k) => {
                return (
                  <a
                    className="text-uppercase"
                    key={k}
                    href={d.url}
                    rel="nofollow"
                  >
                    {d.text}
                  </a>
                );
              })}
            </Nav>
          </InputGroup>

          <div className="header-group-right">
            <Dropdown>
              <Dropdown.Toggle
                className="header_btn"
                id={"dropdownMenuBtnInicio"}
              >
                <Arrowdown />
              </Dropdown.Toggle>
              <Dropdown.Menu>
                {seo_menu.map((s, k) => {
                  return (
                    <Dropdown.Item
                      key={k}
                      href={`${!s.target ? `${prefix}/` : ""}${s.url}`}
                      target={s.target || null}
                    >
                      {s.name}
                    </Dropdown.Item>
                  );
                })}
              </Dropdown.Menu>
            </Dropdown>
            <Button
              href={acceder}
              rel="nofollow"
              // onClick={handleShow}
              className="acceder-button header_btn"
            >
              Acceder
            </Button>
            <Button
              href={regis}
              rel="nofollow"
              className="registrate-button header_btn"
            >
              Regístrate
            </Button>
          </div>
        </Container>
      )}
    </Navbar>
  );
}

export default Header;
