import { SPEEDSIZE_PREFIX } from "../App";
import { Facebook, Twitter, Instagram, Youtube, CodereChat } from "../icons";

import { Image, ListGroupItem, NavLink } from "react-bootstrap";

const Footer = ({ device }) => {
  const footer = [
    {
      title: "SOBRE NOSOTROS",
      itemList: [
        {
          name: "Juego más seguro",
          url: "https://www.codere.es/ayuda/juego-responsable",
          rel: "nofollow",
        },
        {
          name: "Juego Autorizado",
          url: "https://www.ordenacionjuego.es/es/op-CodereOnline",
          rel: "nofollow",
        },
        {
          name: "Contrato",
          url: "https://m.apuestas.codere.es/csbgonline/condicionesgenerales/CondicionesGeneralesOnlineNacional.pdf?_ga=1.14119028.2134564450.1475767251",
          rel: "nofollow",
          target: "_blank",
        },
        {
          name: "Reglas",
          url: "https://www.codere.es/ayuda/reglas-particulares",
          rel: "nofollow",
        },
        {
          name: "Protección Datos Jugadores",
          url: "https://m.apuestas.codere.es/csbgonline/condicionesgenerales/CondicionesGeneralesOnlineNacional.pdf#proteccion_datos_personales",
          rel: "nofollow",
        },
        {
          name: "Política de Cookies",
          url: "https://www.codere.es/ayuda/politica-de-cookies",
          rel: "nofollow",
        },
        {
          name: "Afiliados de Codere",
          url: "https://www.codere-partners.com/es/",
          rel: "nofollow",
          target: "_blank",
        },
        {
          name: "Política de Privacidad",
          url: "https://www.codere.es/ayuda/politica-de-privacidad",
          rel: "nofollow",
        },
      ],
    },
    {
      title: "LINKS DESTACADOS",
      itemList: [
        {
          name: "Descarga App",
          url: "https://www.codere.es/ayuda/apuesta-en-tu-movil",
        },
        {
          name: "Resultados y Estadísticas",
          url: "https://www.codere.es/ayuda/resultados-y-estadisticas",
        },
        { name: "Casino", url: "https://www.codere.es/casino" },
        {
          name: "Ruleta",
          url: "https://www.codere.es/casino/ruleta",
        },
        {
          name: "Slots",
          url: "https://www.codere.es/casino/slots",
        },
        {
          name: "Blackjack",
          url: "https://www.codere.es/casino/blackjack",
        },
        {
          name: "Jackpots",
          url: "https://www.codere.es/casino/jackpots",
        },
        {
          name: "Tarjeta Codere",
          url: "https://m.apuestas.codere.es/deportesEs/#/mso/CodereCardPage",
        },
      ],
    },
    {
      title: "DEPORTES",
      itemList: [
        {
          name: "Info Depósitos y Cobros",
          url: "https://www.codere.es/ayuda/deposito-online",
        },
        {
          name: "Cómo Apostar",
          url: "https://www.codere.es/ayuda/como-y-donde-apostar",
          rel: "nofollow",
        },
        {
          name: "Apuestas Recomendadas",
          url: "https://www.codere.es/cuotas-deportivas",
        },
        {
          name: "Eventos Deportivos",
          url: "https://www.codere.es/eventos-deportivos",
        },
        {
          name: "Blog Codere",
          url: "https://blog.codere.es/",
          target: "_blank",
        },
        {
          name: "Deportes",
          url: "https://m.apuestas.codere.es/deportesEs/#/HomePage",
          rel: "nofollow",
        },
      ],
    },
    {
      title: "AYUDA",
      itemList: [
        { name: "Ayuda", url: "https://www.codere.es/ayuda" },
        {
          name: "T&C Web",
          url: "https://www.codere.es/ayuda/terminos-y-condiciones",
          rel: "nofollow",
        },
        {
          name: "Protección Datos Web",
          url: "https://www.codere.es/ayuda/proteccion-datos-web",
          rel: "nofollow",
        },
        {
          name: "Contacto",
          url: "mailto:apuestas@codere.com",
          rel: "nofollow",
        },
        { name: "+91 343 29 50", url: "callto:91 343 29 50", rel: "nofollow" },
        {
          name: "Online chat",
          url: "https://m.apuestas.codere.es/deportesEs/#/mso/ContactPage",
          rel: "nofollow",
        },
      ],
    },
  ];

  const logosPrefix = "https://www.codere.es/assets1/logosAndSponsors/newImges";
  const prefix = "https://www.codere.es/assets1/logosAndSponsors";

  const payment = [
    {
      src: `${logosPrefix}/cs-logo_Bizum.webp`,
      name: "bizum",
    },
    {
      src: `${logosPrefix}/cs-logo_LocalCodere.webp`,
      name: "Codere",
    },
    {
      src: `${logosPrefix}/cs-logo_Visa.webp`,
      name: "visa",
    },
    {
      src: `${logosPrefix}/cs-logo_Mastercard.webp`,
      name: "MasterCard",
    },
    {
      src: `${logosPrefix}/cs-logo-TransferenciaBancaria.webp`,
      name: "Transferencia",
    },
    {
      src: `${logosPrefix}/cs-logo_Neteller.webp`,
      name: "Neteller",
    },
    {
      src: `${logosPrefix}/cs-logo_Skrill.webp`,
      name: "Skrill",
    },
    {
      src: `${logosPrefix}/cs-logo_PayPal-darkbkg.webp`,
      name: "Paypal",
    },
    {
      src: `${logosPrefix}/cs-logo_PaySafe-darkbkg.webp`,
      name: "Pay-Safe-Card",
    },
    {
      src: `${logosPrefix}/cs-logo_HalCash.webp`,
      name: "Halcash",
    },
    {
      src: `${logosPrefix}/cs-logo_RapidTransfer-darkbkg.webp`,
      name: "RapidTransfer",
    },
    {
      src: `${logosPrefix}/cs-logo-transferencia-inmediata.webp`,
      name: "Transferencia-Inmediata",
    },
  ];

  const jugar = [
    { name: "PlayResponsible.webp" },
    { name: "autopro.webp", url: "https://www.ordenacionjuego.es/es/rgiaj" },
    { name: "JugarSeguro.svg", url: "https://www.juegoseguro.es/" },
    { name: "JugarBien.svg", url: "https://jugarbien.es/" },
    {
      name: "plus18.webp",
      url: "https://www.codere.es/Paginas/juego-responsable.aspx#procreg",
    },
    { name: "sin-dev.webp" },
  ];

  const mobile = [
    {
      name: "appstore.svg",
      url: "https://codere.onelink.me/3k3c?pid=SplashPage_ES&c=iOS&af_dp=codere%3A%2F%2F&af_web_dp=https%3A%2F%2Fwww.codere.es%2Fayuda%2Fapuesta-en-tu-movil",
    },
    {
      name: "android.webp",
      url: "https://codere.onelink.me/3k3c?pid=SplashPage_ES&c=iOS&af_dp=codere%3A%2F%2F&af_web_dp=https%3A%2F%2Fwww.codere.es%2Fayuda%2Fapuesta-en-tu-movil",
    },
  ];

  return (
    <footer className="nb-footer mb-5">
      <div className="container">
        <div className="social-media text-center mt-4">
          <div className="list-inline d-flex justify-content-center gap-3">
            <a
              href="https://m.apuestas.codere.es/deportesEs/#/mso/ContactPage"
              rel="nofollow"
            >
              <CodereChat />
            </a>
            <a href="https://www.facebook.com/CodereApuestas/" rel="nofollow">
              <Facebook />
            </a>
            <a href="https://www.instagram.com/codereapuestas/" rel="nofollow">
              <Instagram />
            </a>
            <a href="https://twitter.com/@Codereapuestas" rel="nofollow">
              <Twitter />
            </a>
            <a
              href="https://www.youtube.com/channel/UCnHiADE4Tfzg94iTGKx9WOQ"
              rel="nofollow"
            >
              <Youtube />
            </a>
          </div>
        </div>

        <div className="apps mt-4 text-center">
          {mobile.map((m) => (
            <a key={m.name} target="_blank" href={m.url}>
              <img src={`${SPEEDSIZE_PREFIX}/${prefix}/${m.name}`} />
            </a>
          ))}
        </div>

        <div
          style={{ gap: "15px", width: device ? "100%" : "65%" }}
          className="d-flex flex-wrap align-items-center justify-content-center mt-3 m-auto"
        >
          {payment.map((payment, k) => (
            <Image
              width={100}
              height={"auto"}
              key={k}
              src={`${SPEEDSIZE_PREFIX}/${payment.src}`}
              alt={payment.name}
            />
          ))}
        </div>

        <div
          className={`d-flex ${
            device && "flex-wrap"
          } justify-content-around mt-5`}
        >
          {footer.map((section, i) => (
            <div className={`${device && "w-50"} mb-4 mb-s-2`} key={i}>
              <span className="footer-title">{section.title}</span>
              <div className="text-white">
                {section.itemList.map((item, j) => (
                  <NavLink
                    className="hover-green footer-item d-block"
                    href={item.url}
                    rel={item.rel}
                    key={j + item.name}
                    target={item.target ?? "_self"}
                  >
                    {item.name}
                  </NavLink>
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>

      <div className="juegas-banners d-flex flex-wrap align-items-center justify-content-center text-center ">
        {jugar.map((j, k) => (
          <ListGroupItem
            key={k}
            href={j.url || null}
            target={j.url ? "_blank" : null}
            rel={j.url ? "nofollow" : null}
            as={j.url ? "a" : "div"}
          >
            <img
              width={100}
              height={100}
              src={`${SPEEDSIZE_PREFIX}/${prefix}/${j.name}`}
              alt={j.name.split(".")[0]}
            />
          </ListGroupItem>
        ))}
      </div>

      <div id="legals">
        <a href="https://m.apuestas.codere.es/csbgonline/condicionesgenerales/politicaseguridadinformacion.pdf">
          <img
            width="40"
            height="80"
            src={`${SPEEDSIZE_PREFIX}/${prefix}/aenor.webp`}
            alt="AENOR Seguridad Información"
          />
        </a>
        <div>
          Codere Apuestas opera en España bajo Codere Online, SAU, con las
          siguientes licencias otorgadas por la DGOJ: 225-11/GA/A86346038/SGR,
          AOC/2014/002, ADC/2014/001, AHC/2014/002, 223-11/GO/ A86346038/SGR,
          MAZ/2015/032, RLT/2016/009, BLJ/2016/007.
        </div>
      </div>
    </footer>
  );
};

export default Footer;
