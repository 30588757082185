import TopGame from "./TopGame";
import BottomGame from "./BottomGame";
import gameCarousel from "../../JSON/mob-games.json";
import deskGames from "../../JSON/desktop-games.json";
import GameSwiper from "./GameSwiper";

// Top Games \\

function Games({ device }) {
  return device ? (
    <div id="mobile_games">
      <span className="game-title"> Juegos Destacados </span>
      <GameSwiper games={gameCarousel.first_slide_list} />
      <span className="game-title"> Nuevos Juegos </span>
      <GameSwiper games={gameCarousel.second_slide_list} />
    </div>
  ) : (
    <div id="desktop_games">
      <span className="game-title"> Mejores Juegos </span>
      <div className="top_games_conatiner">
        {deskGames.top_games.map((item, key) => {
          return <TopGame key={key} banner={item} />;
        })}
      </div>
      <span className="game-title"> Nuevos Juegos </span>

      <div className="bottom_conatiner">
        {deskGames.bottom_games.map((item, key) => {
          return <BottomGame key={key} banner={item} />;
        })}
      </div>
    </div>
  );
}

export default Games;
