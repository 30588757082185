import { Zoom } from "react-awesome-reveal";
import { Accordion, NavLink, Table } from "react-bootstrap";

var questions = [
  "¿En qué consisten las apuestas deportivas?",
  "¿Cuáles son las apuestas más comunes?",
  "¿Cómo empezar a hacer apuestas online?",
  "¿Las apuestas deportivas online son seguras?",
];
var answers = [
  "Las apuestas deportivas son una modalidad de juego en la que los participantes pronostican resultados de eventos deportivos y realizan apuestas basadas en esas predicciones. Los apostantes pueden apostar en diversos resultados, como el desenlace final del encuentro, el marcador exacto, el desempeño de un jugador, entre otros. Este tipo de apuestas se pueden llevar a cabo en eventos deportivos como fútbol, baloncesto, tenis, futbol americano, y muchos mas.",
  "Las apuestas sencillas son una opción popular, donde los apostadores seleccionan un único resultado en un evento deportivo, como el ganador de un partido. Por otro lado, las apuestas en directo ofrecen una experiencia dinámica al permitir a los apostadores realizar pronósticos mientras el partido está en curso, ajustando sus decisiones según el desarrollo del juego. ",
  "Es fundamental familiarizarte con los conceptos básicos de las apuestas deportivas, comprendiendo los diferentes tipos de apuestas disponibles y cómo funcionan las cuotas. Luego, dedica tiempo a investigar sobre los equipos y jugadores, incluyendo su desempeño reciente, posibles lesiones y estrategias de juego.Es crucial establecer un presupuesto de apuestas y seguir una estrategia sólida de gestión del bankroll para evitar pérdidas significativas. Comienza con apuestas pequeñas y aumenta gradualmente tu nivel de experiencia a medida que ganas confianza en tus habilidades de análisis y pronóstico.",
  "Las apuestas deportivas en línea son seguras si se realizan en plataformas legales y reguladas, como Codere. En Codere seleccionamos plataformas fiables y reconocidas, que emplean tecnologías de cifrado seguras para proteger las transacciones financieras y los datos del usuario.",
];

function Content() {
  const prefix = "https://www.codere.es";

  return (
    <div className="content-container container">
      <h1 className="bottomContentTitle text-center">
        Bienvenido a Codere, la Casa de Apuestas favorita de España
      </h1>
      <p className={"coderePaddingInicioP"}>
        En Codere vas a encontrar una gran cantidad y variedad de apuestas
        deportivas seguras para hoy, mañana o dentro de un mes. ¡No importa! En
        línea tendrás a tu disposición una amplia gama de deportes en los que
        podrás colocar tus apuestas online
      </p>

      <nav className="row apuestas">
        <div className="col">
          <NavLink href={`${prefix}/cuotas-deportivas/apuestas-futbol`}>
            Apuestas de fútbol
          </NavLink>
        </div>
        <div className="col">
          <NavLink href={`${prefix}/cuotas-deportivas/apuestas-baloncesto`}>
            Apuestas de baloncesto
          </NavLink>
        </div>
        <div className="col">
          <NavLink href={`${prefix}/cuotas-deportivas/apuestas-tenis`}>
            Apuestas Tenis
          </NavLink>
        </div>
        <div className="col">
          <NavLink href={`${prefix}/eventos-deportivos/apuestas-esport`}>
            Apuestas de e-Sports
          </NavLink>
        </div>
        <div className="col">
          <NavLink href={`${prefix}/cuotas-deportivas/apuestas-formula1`}>
            Apuestas F1
          </NavLink>
        </div>
        <div className="col">
          <NavLink href={`${prefix}/cuotas-deportivas/apuestas-ufc`}>
            Apuestas UFC
          </NavLink>
        </div>
      </nav>

      <div className="row justify-content-evenly">
        <div className="col-12 col-sm-4 left-col">
          <Zoom triggerOnce>
            <h2>Vive la emoción de las Apuestas Deportivas</h2>
            <p>
              Aquí vas a encontrar una amplia variedad de eventos para tus
              apuestas deportivas, desde los más populares hasta los menos
              conocidos, para que siempre encuentres algo que te emocione.
              Además, contamos con una plataforma de apuestas en vivo que te
              permitirá disfrutar de la acción en tiempo real y realizar tus
              pronósticos mientras el partido se desarrolla.
            </p>

            <h3>Conoce los tipos de apuestas en Codere </h3>
            <div>
              <p>
                En el mundo de las apuestas deportivas hay diferentes tipos de
                jugadas con las que podrás divertirte de una forma responsable y
                apasionada en cada uno de los eventos que tú elijas dentro de la
                gran oferta que tenemos para ti en nuestra web y aplicación
                móvil. Entre las más comunes se encuentran:
              </p>
              <ul className={"list-unstyled"}>
                <li className={"mb-2"}>
                  <u>Apuestas sencillas:</u>
                  <span className={"p-lg-2"}>
                    Consiste en seleccionar un único resultado en un evento
                    deportivo y apostar a que ese resultado específico se
                    materializará.
                  </span>
                </li>
                <li className={"mb-2"}>
                  <u>Apuestas combinadas: </u>
                  <span className={"p-lg-2"}>
                    También conocida como parlay, consiste en agrupar múltiples
                    selecciones individuales en una sola apuesta. Para ganar,
                    todas las selecciones deben ser acertadas.
                  </span>
                </li>
                <li className={"mb-2"}>
                  <u>Handicap:</u>
                  <span className={"p-lg-2"}>
                    Un equipo recibe una ventaja o desventaja virtual antes del
                    inicio del evento para nivelar el campo de juego.
                  </span>
                </li>
                <li className={"mb-2"}>
                  <u>Apuestas a ganador: </u>
                  <span className={"p-lg-2"}>
                    También conocida como apuesta de dinero lineal, es cuando
                    apuestas a qué equipo o jugador ganará un evento específico
                    sin considerar puntos de ventaja o desventaja.
                  </span>
                </li>
                <li className={"mb-2"}>
                  <u>Apuestas en directo: </u>
                  <span className={"p-lg-2"}>
                    Realizas apuestas mientras el evento deportivo está en
                    curso, basándote en lo que está sucediendo en tiempo real.
                  </span>
                </li>
                <li className={"mb-2"}>
                  <u>Apuestas más / menos: </u>
                  <span className={"p-lg-2"}>
                    Decides si el resultado final de un evento deportivo será
                    superior (Más) o inferior (Menos) a un número predeterminado
                    de puntos, goles, carreras, etc.
                  </span>
                </li>
                <li className={"mb-2"}>
                  <u>Apuestas al resultado: </u>
                  <span className={"p-lg-2"}>
                    Apuestas específicamente por el resultado final de un evento
                    deportivo, como el ganador del partido, el equipo que avanza
                    en un torneo o el marcador exacto al final del juego.
                    Apuestas al descanso: Realizas una apuesta basada en el
                    resultado al finalizar la primera mitad de un evento
                    deportivo.
                  </span>
                </li>
              </ul>
            </div>
            <h3>Conoce la opción de “Crea tu apuesta” </h3>
            <div>
              <p>
                Es una funcionalidad ofrecida por Codere que permite a los
                usuarios personalizar sus propias combinaciones de apuestas con
                selecciones específicas en un mismo evento deportivo. Los
                usuarios pueden elegir entre diferentes variables, como el
                resultado del partido, el número de goles o puntos anotados, las
                tarjetas amarillas o rojas recibidas, entre otros, y combinarlas
                en una sola apuesta
              </p>
            </div>
          </Zoom>
        </div>
        <div className="col-12 col-sm-4 middle-col">
          <Zoom triggerOnce>
            <h3>Consejos para elegir apuestas online de forma seguras </h3>
            <div>
              <p>
                El juego responsable en las apuestas deportivas es fundamental.
                ¿Cómo podemos establecer un sistema que nos permita jugar de
                forma segura y efectiva? Aquí te presentamos los consejos más
                importantes:
              </p>

              <ol>
                <li>
                  Establecer un presupuesto máximo y utilizar solo cantidades
                  que no afecten a las necesidades básicas. Considerar el dinero
                  destinado al juego como perdido desde el principio.
                </li>
                <li>
                  Evitar intentar recuperar pérdidas, ya que estas son normales
                  en las apuestas deportivas.
                </li>
                <li>
                  Apostar solo en deportes o tipos de apuestas que se conocen
                  bien para evitar pérdidas.
                </li>
                <li>
                  No apostar por impulso y esperar cuando no haya opciones de
                  apuestas conocidas disponibles.
                </li>
                <li>
                  Utilizar la información disponible sobre los equipos y las
                  estrategias básicas para tomar decisiones más informadas y
                  reducir la vulnerabilidad ante la suerte en las apuestas.
                </li>
              </ol>
              <p>
                Recuerda que la información es poder. Como ya hemos dicho:
                conocer a los equipos, conocer sus objetivos, conocerte a ti
                mismo y algunas estrategias básicas harán de ti alguien que,
                como poco, no será vulnerable a cualquier aspecto burdo del azar
                al apostar. Si pierdes, pierde porque tenía que pasar, pero no
                lo pongas fácil.
              </p>
            </div>
            <h2>El emocionante mundo de Casino Online de Codere </h2>
            <div>
              <p>
                Tenemos a disposición de nuestros usuarios el mejor{" "}
                <a href="/casino">casino online</a> de España, con una amplia
                variedad de juegos de casino de marcas tan reconocidas como
                Playtech, Pragmatic, Microgames o EGT:
              </p>
              <ul>
                <li>
                  <u>
                    <a href="/casino/ruleta">Ruleta online </a>
                  </u>
                </li>
                <li>
                  <u>
                    <a href="/casino/blackjack">Blackjack</a>
                  </u>
                </li>
                <li>
                  <u>
                    <a href="/casino/slots">Slots </a>
                  </u>
                </li>
                <li>
                  <u>
                    <a href="/casino/jackpots">Jackpots </a>
                  </u>
                </li>
              </ul>
              <p>
                Explora el emocionante mundo del Casino online de Codere y
                disfruta de una experiencia similar a la de tu plataforma de
                Streaming favorita en la televisión.
              </p>

              <p>
                Con impresionantes Jackpots y una amplia selección de los juegos
                más populares, el Casino online de Codere no tiene nada que
                envidiar al de otras casas de apuestas deportivas y, por
                supuesto, tampoco a los Casinos tradicionales y presenciales.
                ¡Sumérgete en la acción y descubre todo lo que nuestro Casino
                Online tiene para ofrecerte!
              </p>
            </div>
          </Zoom>
        </div>
        <div className="col-12 col-sm-4 right-col">
          <Zoom triggerOnce>
            <h3>Juega Casino en vivo con crupieres de verdad </h3>
            <div>
              <p>
                Las ruletas en vivo de nuestro casino online han revolucionado
                la experiencia de juego en línea al proporcionar una experiencia
                casi idéntica a la de un casino físico. Estas mesas de ruleta
                son operadas por crupieres reales que dirigen el juego en tiempo
                real a través de transmisiones de video en alta definición.{" "}
              </p>

              <p>
                {" "}
                Los jugadores pueden interactuar con el crupier y otros
                participantes a través de un chat en vivo, lo que añade una
                dimensión social a la experiencia de juego en línea.
              </p>
              <p>
                La autenticidad de las ruletas en vivo a menudo se ve reforzada
                por la variedad de opciones de personalización que ofrecen los
                casinos en línea. Los jugadores pueden elegir entre diferentes
                variantes de ruleta, como la ruleta europea, francesa o
                americana.
              </p>
              <p>
                En términos de seguridad y equidad, las ruletas en vivo de
                nuestro casino online están respaldadas por tecnología de
                transmisión segura y generadores de números aleatorios
                certificados. Esto garantiza que los resultados del juego sean
                totalmente aleatorios y que los jugadores puedan confiar en la
                integridad de la experiencia de juego.{" "}
              </p>

              <p>
                {" "}
                En resumen, las ruletas en vivo ofrecen una emocionante y
                conveniente alternativa a los casinos físicos, brindando la
                emoción del juego en tiempo real directamente a la pantalla de
                tu dispositivo.
              </p>
            </div>

            <h2>Juego responsable</h2>
            <div>
              <p>
                El juego responsable es una prioridad fundamental en Codere. Nos
                comprometemos a garantizar que nuestros jugadores disfruten de
                una experiencia de juego segura y saludable.{" "}
              </p>
              <p>
                {" "}
                Para nosotros, el juego responsable implica proporcionar
                herramientas y recursos para que los jugadores controlen su
                comportamiento de juego y eviten cualquier posible impacto
                negativo.
              </p>
              <p>
                Ofrecemos una variedad de herramientas de control de juego, como
                límites de depósito, límites de pérdida, límites de tiempo de
                juego y la opción de autoexclusión. Estas herramientas permiten
                a los jugadores establecer límites en sus actividades de juego,
                controlar su presupuesto y tomar descansos cuando sea necesario.
              </p>
              <p>
                En nuestro casino, nos esforzamos por promover prácticas de
                juego responsables y fomentar un ambiente seguro y divertido
                para todos nuestros jugadores.{" "}
              </p>

              <p>
                {" "}
                Creemos que al tomar medidas proactivas para promover el juego
                responsable, podemos ayudar a garantizar que el juego permanezca
                como una forma de entretenimiento positiva y gratificante para
                todos nuestros usuarios.
              </p>
            </div>
          </Zoom>
        </div>
      </div>
      <Table striped bordered hover variant="dark" className="mb-4">
        <tbody>
          <tr>
            <td>Inicio de operaciones</td>
            <td>2008</td>
          </tr>
          <tr>
            <td>Apuestas Deportivas</td>
            <td>Apuestas fútbol, Apuestas tenis, Apuestas baloncesto y más</td>
          </tr>
          <tr>
            <td>Licencia</td>
            <td className={"tdWrapper"}>
              <span className={"tdWrapperSpan"}>
                Licencias otorgadas por la DGOJ: 225-11/GA/A86346038/SGR,
                AOC/2014/002, ADC/2014/001, AHC/2014/002, 223-11/GO/
                A86346038/SGR,MAZ/2015/032,RLT/2016/009, BLJ/2016/007.
              </span>
            </td>
          </tr>
          <tr>
            <td>Moneda</td>
            <td>EUR</td>
          </tr>
          <tr>
            <td>Depósito mínimo</td>
            <td>10€</td>
          </tr>
        </tbody>
      </Table>

      <section>
        <h2 className="bottomContentTitle">FAQ’S apuestas deportivas</h2>
        <Accordion className="content-accordion">
          {answers.map((answer, index) => {
            return (
              <Accordion.Item key={index} eventKey={index}>
                <Accordion.Header as="h3">{questions[index]}</Accordion.Header>
                <Accordion.Body>
                  <p>{answer}</p>
                </Accordion.Body>
              </Accordion.Item>
            );
          })}
        </Accordion>
      </section>
    </div>
  );
}

export default Content;
